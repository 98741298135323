import React, { useState } from 'react';
import SimpleToolbar from '../../../components/SimpleToolbar';
import DateFilter from '../../../components/DateFilter';
import { Range } from 'react-date-range';
import Download from '@mui/icons-material/Download';
import { GridFilterAltIcon } from '@mui/x-data-grid';
import moment from 'moment';
import DatePicker from '../../../components/DatePicker';
import { toTwoDecimals } from '../../../lib';

type Props = {
  rows: any[];
  id: string | undefined;
};

const SingleCustomReportToolbar: React.FC<Props> = ({ rows, id }) => {
  const handleDownloadClickTable = () => {
    const csvHeaders = ['Date', 'M. Name', 'Click Id', 'Revenue'];

    const escapeValue = (value: any) => {
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value.replace(/"/g, '""')}"`;
      }
      return value;
    };

    const csvRows = rows.map((row) => {
      const date = escapeValue(row?.date);
      const merchantName = escapeValue(row?.merchantName);
      const clickId = escapeValue(row?.clickId);
      const revenue = toTwoDecimals(row?.revenue);

      return [date, merchantName, clickId, revenue];
    });

    const csvContent = [csvHeaders, ...csvRows]
      .map((e) => e.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${rows[0].date}-${id}-clicks.csv`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const modalButtons = [
    {
      name: 'downloadTable',
      label: 'Download Table Data',
      renderIf: true,
      icon: <Download />,
      onClick: handleDownloadClickTable,
      type: 'button',
    },
  ];

  return <SimpleToolbar modalButtons={modalButtons} />;
};

export default SingleCustomReportToolbar;
