import { Box, Grid, Paper, Typography } from '@mui/material';
import { useContext } from 'react';
import IronDomeMemberSelector from './IronDomeMemberSelector';
import IronDomeProvider, { IronDomeContext } from './context';
import TrafficBlockForm from '../traffic/TrafficBlockForm';
import useOffer from '../offer/useOffer';
import { TrafficSource } from '../traffic/types';

const IronDomeContainer = () => {
  const { selectedMember, setOffers, offers } = useContext(IronDomeContext);

  const offerClient = useOffer();

  const onSubmitRules = async (rules: TrafficSource['blockRules']) => {
    const offerId = selectedMember._id;
    const newOffer = { ...selectedMember, blockRules: rules };

    const api = offerClient.editOffer();
    await api.call(offerId, newOffer);

    const newOffers = offers.map((offer: any) => {
      if (offer._id === offerId) return newOffer;

      return offer;
    });

    setOffers(newOffers);
  };

  return (
    <Grid container sx={{ mt: 1 }} spacing={2}>
      <Grid item xs={3}>
        <IronDomeMemberSelector></IronDomeMemberSelector>
      </Grid>
      <Grid item xs={9}>
        {selectedMember ? (
          <Paper>
            <Box p={2}>
              <Typography variant="h4" sx={{ mb: 3 }}>
                {selectedMember.name}
              </Typography>

              <TrafficBlockForm
                key={selectedMember._id}
                blockRules={selectedMember?.blockRules}
                onSubmit={onSubmitRules}
                isOfferLevel
              ></TrafficBlockForm>
            </Box>
          </Paper>
        ) : (
          'No selected offer'
        )}
      </Grid>
    </Grid>
  );
};

const IronDomePage = () => {
  return (
    <IronDomeProvider>
      <IronDomeContainer />
    </IronDomeProvider>
  );
};

export default IronDomePage;
