import React, { useState, useContext } from 'react';
import {
  Card,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  InputAdornment,
  Link,
  Grid,
  Divider,
  IconButton,
} from '@mui/material';
import {
  AccountCircle,
  Lock,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import useApi from '../../hooks/useApi';
import { useNavigate, Navigate, useSearchParams } from 'react-router-dom';
import { getAxiosErrorMessage } from '../../lib';
import { AuthContext } from '../../context/authContext';

const DEFAULT_CHALLENGE_PARAMS = {
  challengeName: '',
  session: '',
  newPassword: '',
  username: '',
};

function Login() {
  const [user, setUser] = useState({ password: '', username: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [queries, setQueries] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);

  const { api: apiClient } = useApi();

  const [challengeRequired, setChallengeRequired] = useState(false);
  const [challengeParams, setChallengeParams] = useState(
    DEFAULT_CHALLENGE_PARAMS
  );

  const { clearCredentials, saveCredentials, isAuthenticated } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleNewPasswordChange = (e: any) => {
    const { value } = e.target;
    setChallengeParams({
      ...challengeParams,
      newPassword: value,
    });
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError('');
      const { data } = await apiClient.post('/api/auth/login', user);

      const { ChallengeName, Session } = data;

      if (ChallengeName) {
        setChallengeRequired(true);
        setChallengeParams({
          challengeName: ChallengeName,
          session: Session,
          newPassword: '',
          username: user.username,
        });
      } else {
        saveCredentials(data);
        navigate('/');
      }
    } catch (e: any) {
      console.error(e);
      setError(e.response.data.message ?? 'Something went wrong...');
      clearCredentials();
    } finally {
      setLoading(false);
    }
  };

  const onChallengeSubmit = async () => {
    try {
      setLoading(true);
      setError('');
      const { data } = await apiClient.post(
        '/api/auth/challenge',
        challengeParams
      );

      saveCredentials(data);

      setChallengeRequired(false);
      navigate('/');
    } catch (e: any) {
      console.error(e);
      setError(e.response.data.message ?? 'Something went wrong...');
      clearCredentials();
      setChallengeParams({ ...challengeParams, newPassword: '' });
    } finally {
      setLoading(false);
    }
  };

  if (isAuthenticated) {
    const destination = queries.get('r') ?? '/admin';

    return <Navigate to={destination} />;
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Grid
      container
      // spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh' }}
    >
      <Grid item xs={12} md={4} lg={3} height={'100%'}>
        <Card sx={{ width: '80%', boxShadow: 3, pb: 3, borderRadius: 4 }}>
          <Box display="flex" flexDirection="column" gap={2} p={2}>
            <Typography
              variant="h5"
              textAlign={'center'}
              width={'100%'}
              sx={{ fontWeight: '600' }}
            >
              {challengeRequired ? 'New password required' : 'LOGIN'}
            </Typography>
            <Divider />
            <Typography
              textAlign={'center'}
              sx={{ fontSize: '0.9rem', mb: 2, mx: 3 }}
            >
              To keep connected with us please login with your username and
              password.
            </Typography>
            {error && (
              <Alert sx={{ mb: 3 }} severity="error">
                {error}
              </Alert>
            )}
            <Box display="flex" flexDirection="column" gap={3} px={3}>
              {challengeRequired ? (
                <>
                  <TextField
                    onChange={handleNewPasswordChange}
                    type="password"
                    value={challengeParams.newPassword}
                    placeholder="New password"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button variant="contained" onClick={onChallengeSubmit}>
                    {loading ? <CircularProgress /> : 'Submit'}
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    onChange={handleChange}
                    name="username"
                    value={user.username}
                    variant="outlined"
                    label="Username"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    onChange={handleChange}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={user.password}
                    variant="outlined"
                    label="Password"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword
                                ? 'hide the password'
                                : 'display the password'
                            }
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button variant="contained" onClick={onSubmit}>
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: 'white' }} />
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Login;
