import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';

import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import SimpleModal from './SimpleModal';

type Props = {
  initialData?: Date;
  onSubmit: (newDate: Date) => any;
  handleClose: any;
  open: boolean;
  title?: string;
};

const DatePicker = ({
  initialData = new Date(),
  onSubmit,
  open,
  title,
  handleClose,
}: Props) => {
  const [date, setDate] = useState(initialData);

  const handleSubmit = () => {
    onSubmit(date);
    handleClose();
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h5" gutterBottom>{title || 'Select a date'}</Typography>
      <Calendar date={date} onChange={setDate} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ marginRight: 1 }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </SimpleModal>
  );
};

export default DatePicker;
