import React, { useContext, useEffect, useState } from 'react';
import { OfferGroupContext } from '../context';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { MerchantCommissionSettings } from '../types';
import CommissionDeductionToolbar from './toolbar';
import { nanoid } from 'nanoid';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModal from '../../../components/ConfirmModal';
import useCustomOffer from '../useCustomOffers';

type Props = {};

const CommissionDeduction = (props: Props) => {
  const [rows, setRows] = useState<MerchantCommissionSettings[]>([]);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>(undefined);

  const { offerGroup, merchants, setOfferGroup } = useContext(OfferGroupContext);
  const client = useCustomOffer();

  const map = Object.fromEntries(
    merchants.map((m) => [m.merchantId, m.merchantName])
  );

  const init = () => {
    if (!offerGroup) return;

    const rows = offerGroup.merchantCommissionSettings ?? [];
    const newRows = rows.map((i) => ({ ...i, _id: i._id ?? nanoid() }));

    setRows(newRows);
  };

  const onDeleteDeduction = async (id: string) => {
    let comSettings = offerGroup!.merchantCommissionSettings.slice() ?? [];

    const updatedComSettings = comSettings.filter((settings) => settings._id !== id);

    const newOfferGroup = {
      ...offerGroup!,
      merchantCommissionSettings: updatedComSettings,
    };

    await client
      .updateOfferGroup()
      .call(offerGroup!._id, { merchantCommissionSettings: updatedComSettings });

    setOfferGroup(newOfferGroup);
  };

  const handleDelete = (id: string) => {
    onDeleteDeduction(id);
    setRows((prevRows) => prevRows.filter((row) => row._id !== id));
  };

  const cols: GridColDef<MerchantCommissionSettings>[] = [
    {
      headerName: 'M. Id',
      field: 'merchantId',
      flex: 1,
    },
    {
      headerName: 'M. Name',
      field: '',
      flex: 1,
      valueGetter: (params) => {
        return map[params.row.merchantId] ?? 'No merchant name';
      },
    },
    {
      headerName: 'Deduction rate',
      field: 'deductionRate',
      flex: 1,
      valueFormatter: (params) => {
        if (params.value == null) return '';

        const decimal = params.value.toFixed(2);

        return `${decimal}%`;
      },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      renderCell: (params) => {
        return (
          <ConfirmModal
            iconButton={true}
            icon={<DeleteIcon />}
            title="Delete"
            onConfirm={() => handleDelete(params.row._id!)}
          >
            Are you sure you want to delete this row? This action cannot be undone.
          </ConfirmModal>
        );
      },
    },
  ];

  useEffect(() => {
    init();
  }, [offerGroup]);

  useEffect(() => {
    if (selectedRow.length > 0) {
      const currRow = rows.find((row) => row._id === selectedRow[0]);

      const merchantIds = selectedRow.map((item) => {
        const res = rows.find((row) => row._id === item)
        return res?.merchantId
      })
      const res = {
        deductionRate: currRow?.deductionRate,
        merchantIds: merchantIds,
      };
      setSelectedRowData(res);
    }
    else {
      setSelectedRowData(null)
    }
  }, [selectedRow]);

  console.log('selectedRow :>> ', selectedRow);

  return (
    <>
      <DataGrid
        rows={rows}
        getRowId={(r) => r._id!}
        columns={cols}
        autoHeight
        checkboxSelection
        onRowSelectionModelChange={(newSelection) => setSelectedRow(newSelection as string[])}
        slots={{
          toolbar: () => {
            return <CommissionDeductionToolbar selectedRow={selectedRowData} />;
          },
        }}
      />
    </>
  );
};

export default CommissionDeduction;
