import { Box, TextField, Checkbox, FormControlLabel } from '@mui/material';
import SimpleRadioGroup from '../../../components/RadioGroup';
import SimpleCheckboxGroup from '../../../components/Checkboxes';
import { useState } from 'react';
import LoadingButton from '../../../components/LoadingButton';
import CountrySelector from '../CountrySelector';
import { TrafficSource } from '../types';

type Props = {
  blockRules?: TrafficSource['blockRules'];
  onSubmit: (rules: TrafficSource['blockRules']) => any;
  isOfferLevel?: boolean;
};

const initialValue: TrafficSource['blockRules'] = {
  blockAnon: true,
  blockDataCenter: true,
  blockBadIps: [],
  blockLocations: {
    type: 'blacklist',
    locations: [],
  },
  globalFallback: '',
  ipThrottle: {
    ipThrottling: false,
    window: 1440,        
    maxRequests: 1,      
    blacklistFor: 1440,  
  },
};

const TrafficBlockForm = ({
  blockRules: initialBlock,
  onSubmit,
  isOfferLevel = false,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ window?: string; maxRequests?: string; blacklistFor?: string }>({});

  const [blockRules, setBlockRules] = useState({
    ...initialValue,
    ...initialBlock,
    ipThrottle: {
      ipThrottling: initialBlock?.ipThrottle?.ipThrottling ?? false,
      window: initialBlock?.ipThrottle?.window ?? 1440,
      maxRequests: initialBlock?.ipThrottle?.maxRequests ?? 1,
      blacklistFor: initialBlock?.ipThrottle?.blacklistFor ?? 1440,
    },
  });

  const validateIpThrottle = () => {
    const newErrors: any = {};
    if (blockRules.ipThrottle.ipThrottling) {
      if (!blockRules.ipThrottle.window) {
        newErrors.window = 'Window is required';
      }
      if (!blockRules.ipThrottle.maxRequests) {
        newErrors.maxRequests = 'Max Requests for Window is required';
      }
      if (!blockRules.ipThrottle.blacklistFor) {
        newErrors.blacklistFor = 'Blacklist for is required';
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateIpThrottle()) {
      return;
    }
    setLoading(true);
    try {
      await onSubmit(blockRules);
    } catch (error) {
      console.error("Error submitting traffic source rules", error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (key: string, newValue: any) => {
    setBlockRules({ ...blockRules, [key]: newValue });
  };

  const handleIpThrottleToggle = () => {
    setBlockRules({
      ...blockRules,
      ipThrottle: {
        ...blockRules.ipThrottle,
        ipThrottling: !blockRules.ipThrottle.ipThrottling,
      },
    });
  };

  const handleIpThrottleInputChange = (key: string, value: any) => {
    setBlockRules({
      ...blockRules,
      ipThrottle: {
        ...blockRules.ipThrottle,
        [key]: value,
      },
    });

    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [key]: undefined }));
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3} mb={2}>
        <TextField
          label="Global fallback"
          helperText="(Optional) This fallback will be used if the offer does not have a fallback link"
          value={blockRules.globalFallback}
          onChange={(v) => onChange('globalFallback', v.target.value)}
          disabled={isOfferLevel}
        ></TextField>

        <Box display="flex" gap={4} flexDirection="row">
          <SimpleRadioGroup
            fields={[
              { value: true, label: 'Block' },
              { value: false, label: 'Allow' },
            ]}
            label="Block anonymous"
            onChange={(v: any) => onChange('blockAnon', v)}
            value={blockRules.blockAnon}
            helperText="Tor and proxies identified by AWS"
          ></SimpleRadioGroup>

          <SimpleRadioGroup
            fields={[
              { value: true, label: 'Block' },
              { value: false, label: 'Allow' },
            ]}
            label="Block Data center & VPNs"
            onChange={(v: any) => onChange('blockDataCenter', v)}
            value={blockRules.blockDataCenter}
          ></SimpleRadioGroup>

          <SimpleCheckboxGroup
            fields={[
              { value: 'gen', label: 'General Malicious IPs' },
              { value: 'ddos', label: 'DDOS' },
              { value: 'recon', label: 'Reconnaissance/Crawlers' },
            ]}
            label="Block Malicious IP"
            onChange={(v: any) => onChange('blockBadIps', v)}
            value={blockRules.blockBadIps}
          ></SimpleCheckboxGroup>

          <Box display="flex" flexDirection="column">
            <FormControlLabel
              control={
                <Checkbox
                  checked={blockRules.ipThrottle?.ipThrottling ?? false} 
                  onChange={handleIpThrottleToggle}
                />
              }
              label="Enable IP Throttling"
            />

            {blockRules.ipThrottle?.ipThrottling && (
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  size="small"
                  label="Window (minutes)"
                  type="number"
                  value={blockRules.ipThrottle?.window ?? 1440} 
                  onChange={(e) =>
                    handleIpThrottleInputChange('window', Math.max(0, parseInt(e.target.value)))
                  }
                  inputProps={{
                    min: 0,
                    step: 1,
                    onWheel: (e) => {
                      e.preventDefault();
                      (e.target as HTMLElement).blur(); 
                    },
                  }}
                  required
                  error={!!errors.window} 
                  helperText={errors.window}
                />
                <TextField
                  size="small"
                  label="Max Requests for Window"
                  type="number"
                  value={blockRules.ipThrottle?.maxRequests ?? 1}  
                  onChange={(e) =>
                    handleIpThrottleInputChange('maxRequests', Math.max(1, parseInt(e.target.value)))
                  }
                  inputProps={{
                    min: 1,
                    step: 1,
                    onWheel: (e) => {
                      e.preventDefault();
                      (e.target as HTMLElement).blur(); 
                    },
                  }}
                  required
                  error={!!errors.maxRequests}
                  helperText={errors.maxRequests}
                />
                <TextField
                  label="Blacklist for (minutes)"
                  type="number"
                  size="small"
                  value={blockRules.ipThrottle?.blacklistFor ?? 1440}
                  onChange={(e) =>
                    handleIpThrottleInputChange('blacklistFor', Math.max(-1, parseInt(e.target.value)))
                  }
                  inputProps={{
                    min: 0,
                    step: 1,
                    onWheel: (e) => {
                      e.preventDefault();
                      (e.target as HTMLElement).blur(); 
                    },
                  }}
                  required
                  error={!!errors.blacklistFor}
                  helperText={errors.blacklistFor}
                />
              </Box>
            )}
          </Box>
        </Box>
        <CountrySelector
          value={blockRules.blockLocations}
          onChange={(v: any) => onChange('blockLocations', v)}
        />
      </Box>
      <LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>
        Submit
      </LoadingButton>
    </>
  );
};

export default TrafficBlockForm;
