import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import useCustomOffer from '../useCustomOffers';
import { useParams } from 'react-router-dom';
import { toTwoDecimals } from '../../../lib';
import { Range } from 'react-date-range';
import OfferGroupClicksTableToolbar from './toolbar';
import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment';

type Props = {};

interface RowData {
  merchantId: string;
  merchantName: string;
  actualClicks: number;
  reportedClicks: number;
  actualRevenue: number;
  reportedRevenue: number;
  clickDifference?: number;
  revenueDifference?: number;
  averageCpc?: number;
}

const OfferGroupClicksTable = (props: Props) => {
  const [rows, setRows] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(false);
  const [actualClicks, setActualClicks] = useState<number[]>([]);
  const [customOfferLabel , setCustomOfferLabel ] = useState<string>('')
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const params = useParams();
  const client = useCustomOffer();

  useEffect(() => {
    init()
  },[])

  const init = async () => {
    const api = client.getOfferGroup()
    const res = await api.call(params.id)
    setCustomOfferLabel(res?.label)
  }

  const fetchData = async (
    startDate: Date | undefined,
    endDate: Date | undefined
  ) => {
    const api = client.getClicksSummary();
    setLoading(true);
    try {
      const startDateFinal =
        moment(startDate).format('YYYY-MM-DD') ||
        moment(new Date()).format('YYYY-MM-DD');
      const endDateFinal =
        moment(endDate).format('YYYY-MM-DD') ||
        moment(new Date()).format('YYYY-MM-DD');

      const res = await api.call(params.id, startDateFinal, endDateFinal);

      if (res.actual && res.actual.length > 0) {
        setActualClicks(res.actualClicks);

        const reportedMap = res.reported.reduce((acc: any, item: any) => {
          const { merchantId, merchantName } = item;
          const key = `${merchantId};${merchantName}`;
          acc[key] = item;
          return acc;
        }, {});

        const actualRows: RowData[] = res.actual.map((item: any) => {
          const { merchantId, merchantName, revenue, clicks } = item;
          const key = `${merchantId};${merchantName}`;

          const reported = reportedMap[key];

          const base: RowData = {
            merchantId,
            merchantName,
            actualClicks: clicks,
            actualRevenue: revenue,
            reportedClicks: 0,
            reportedRevenue: 0,
          };

          if (!reported) return base;

          const { clicks: rclicks, revenue: rrevenue } = reported;

          base.reportedClicks = rclicks;
          base.reportedRevenue = rrevenue;

          return base;
        });

        const totalRow: RowData = calculateTotals(actualRows);

        setRows([totalRow, ...actualRows]);
      } else {
        const totalRow: RowData = calculateTotals([]);
        setRows([totalRow]);
      }
    } catch (error) {
      const totalRow: RowData = calculateTotals([]);
      setRows([totalRow]);
    }
    setLoading(false);

    return api.abort ? api.abort() : undefined;
  };

  const customSortComparator = (
    value1: any,
    value2: any,
    params1: any,
    params2: any
  ) => {
    const num1 = parseFloat(value1);
    const num2 = parseFloat(value2);
    if (isNaN(num1) || params1.id === '') return 0;
    if (isNaN(num2) || params2.id === '') return 0;

    return num1 - num2;
  };

  const calculateTotals = (rows: RowData[]): RowData => {
    if (rows.length === 0) {
      return {
        merchantId: '',
        merchantName: '',
        actualClicks: 0,
        reportedClicks: 0,
        actualRevenue: 0,
        reportedRevenue: 0,
        clickDifference: 0,
        revenueDifference: 0,
        averageCpc: 0,
      };
    }

    const total = rows.reduce(
      (acc, row) => {
        acc.actualClicks += row.actualClicks;
        acc.reportedClicks += row.reportedClicks;
        acc.actualRevenue += row.actualRevenue;
        acc.reportedRevenue += row.reportedRevenue;
        return acc;
      },
      {
        merchantId: '',
        merchantName: '',
        actualClicks: 0,
        reportedClicks: 0,
        actualRevenue: 0,
        reportedRevenue: 0,
      } as RowData
    );

    total.clickDifference =
      total.actualClicks > 0
        ? toTwoDecimals(total.reportedClicks / total.actualClicks) * 100
        : 0;

    total.revenueDifference =
      total.actualRevenue > 0
        ? toTwoDecimals(total.reportedRevenue / total.actualRevenue) * 100
        : 0;

    total.averageCpc =
      total.actualClicks + total.reportedClicks > 0
        ? toTwoDecimals(
            (total.actualRevenue + total.reportedRevenue) /
              (total.actualClicks + total.reportedClicks)
          )
        : 0;

    return total;
  };

  const handleDateFilterSubmit = (range: Range) => {
    setDateRange({
      startDate: range.startDate || new Date(),
      endDate: range.endDate || new Date(),
      key: range.key,
    });
    fetchData(range.startDate, range.endDate);
  };

  const columns: GridColDef[] = [
    {
      headerName: 'M. Id',
      field: 'merchantId',
      flex: 1,
      sortComparator: customSortComparator,
    },
    {
      headerName: 'M. Name',
      field: 'merchantName',
      flex: 1,
      sortComparator: customSortComparator,
    },
    {
      headerName: 'Reported Clicks',
      field: 'reportedClicks',
      flex: 1,
      sortComparator: customSortComparator,
    },
    {
      headerName: 'Actual Clicks',
      field: 'actualClicks',
      flex: 1,
      sortComparator: customSortComparator,
    },
    {
      headerName: 'Clk. diff. %',
      field: 'clickDifference',
      flex: 1,
      sortComparator: customSortComparator,
      valueGetter: (params) => {
        const { actualClicks, reportedClicks } = params.row;
        const diff = actualClicks <= 0 ? 0 : 1 - reportedClicks / actualClicks;
        return diff;
      },
      valueFormatter: (params) => {
        const r = toTwoDecimals(params.value * 100);
        return `${r}%`;
      },
    },
    {
      headerName: 'Reported Revenue',
      field: 'reportedRevenue',
      flex: 1,
      sortComparator: customSortComparator,
      valueFormatter: (params) => {
        const num = toTwoDecimals(params.value);
        return `$${num}`;
      },
    },
    {
      headerName: 'Actual Revenue',
      field: 'actualRevenue',
      flex: 1,
      sortComparator: customSortComparator,
      valueFormatter: (params) => {
        const num = toTwoDecimals(params.value);
        return `$${num}`;
      },
    },
    {
      headerName: 'Rev. diff. %',
      field: 'revenueDifference',
      flex: 1,
      sortComparator: customSortComparator,
      valueGetter: (params) => {
        const { actualRevenue, reportedRevenue } = params.row;
        const diff =
          actualRevenue <= 0 ? 0 : 1 - reportedRevenue / actualRevenue;
        return diff;
      },
      valueFormatter: (params) => {
        const r = toTwoDecimals(params.value * 100);
        return `${r}%`;
      },
    },
    {
      headerName: 'Avg. CPC',
      field: 'averageCpc',
      flex: 1,
      sortComparator: customSortComparator,
      valueGetter: (params) => {
        const { actualRevenue, reportedRevenue, reportedClicks, actualClicks } =
          params.row;
        const revenue = (actualRevenue || 0) + (reportedRevenue || 0);
        const clicks = (reportedClicks || 0) + (actualClicks || 0);
        return clicks !== 0 ? revenue / clicks : 0;
      },
      valueFormatter: (params) => {
        const r = toTwoDecimals(params.value);
        return `$${r}`;
      },
    },
  ];

  return (
    <>
      <Box>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Chip
            variant="outlined"
            color="primary"
            label={`Starts From: ${moment(dateRange.startDate).format(
              'YYYY-MM-DD'
            )}`}
          ></Chip>
          <Chip
            variant="outlined"
            color="primary"
            label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
          ></Chip>
        </Box>
      </Box>
      <DataGrid
        columns={columns}
        rows={rows}
        autoHeight
        loading={loading}
        getRowId={(r) => r.merchantId}
        components={{
          Toolbar: () => (
            <OfferGroupClicksTableToolbar
              onDateFilterSubmit={handleDateFilterSubmit}
              initialDateRange={dateRange}
              actualClicks={actualClicks}
              customOfferLabel={customOfferLabel}
              rows={rows}
            />
          ),
        }}
        isRowSelectable={(params) => params.row.merchantId !== ''}
      />
    </>
  );
};

export default OfferGroupClicksTable;
