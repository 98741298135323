import { useRouteError } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

export default function NotFoundPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Box display="flex" alignItems="center" width="100%" height="100vh" justifyContent="center" flexDirection="column">
      <Typography variant="h3">Not found</Typography>
      <Typography variant="subtitle1">The page you requested does not exist</Typography>
    </Box>
  );
}
