import React, { useState } from 'react';
import SimpleToolbar from '../../../../components/SimpleToolbar';
import { Range } from 'react-date-range';
import { GridFilterAltIcon } from '@mui/x-data-grid';
import DateFilter from '../DateFilter/index';

type Props = {
  onDateFilterSubmit: (range: Range) => void;
  initialDateRange: Range;
};

const RequestTableToolbar: React.FC<Props> = ({
  onDateFilterSubmit,
  initialDateRange,
}) => {
  const [dateRange, setDateRange] = useState(initialDateRange);

  const handleDatePickerSubmit = (newRange: Range, handleClose: () => void) => {
    setDateRange(newRange);
    onDateFilterSubmit(newRange);
    handleClose();
  };

  const modalButtons = [
    {
      name: 'filter',
      label: 'Date Filter',
      renderIf: true,
      icon: <GridFilterAltIcon />,
      render: (open: boolean, handleClose: () => any) => (
        <DateFilter
          open={open}
          handleClose={handleClose}
          onSubmit={(newRange) => handleDatePickerSubmit(newRange, handleClose)}
          initialData={dateRange}
        />
      ),
    },
  ];

  return <SimpleToolbar modalButtons={modalButtons} />;
};

export default RequestTableToolbar;
