import React, { useContext, useState, useEffect } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { TextField, Typography } from '@mui/material';
import { MerchantData, OfferGroupContext } from '../context';
import LoadingButton from '../../../components/LoadingButton';

import Autocomplete from '@mui/material/Autocomplete';

type Props = {
  open: boolean;
  handleClose: any;
  onSubmit: (data: Deduction) => any;
  initialValue?: Deduction;
  form?: string
};

type Deduction = {
  merchantIds: any[]; 
  deductionRate: any;
};

const DeductionForm = ({
  open,
  handleClose,
  onSubmit,
  initialValue,
  form
}: Props) => {
  const [data, setData] = useState<Deduction>(
    initialValue || {
      merchantIds: [],
      deductionRate: 0,
    }
  );
  const { merchants } = useContext(OfferGroupContext);

  const [selectedMerchants, setSelectedMerchants] = useState<MerchantData[]>([]);

  useEffect(() => {
    if (initialValue) {
      const initialSelectedMerchants = merchants.filter(m =>
        initialValue.merchantIds.includes(m.merchantId)
      );
      setSelectedMerchants(initialSelectedMerchants);
    }
  }, []);

  const [loading, setLoading] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onChangeMerchants = (selected: MerchantData[]) => {
    setSelectedMerchants(selected);
  };

  const handleSubmit = async () => {
    if (selectedMerchants.length === 0) return;

    setLoading(true);
    const deductionRate = Math.min(Math.max(+data.deductionRate, 0), 100);

    try {
      await onSubmit({
        merchantIds: selectedMerchants.map(m => m.merchantId), // Collect IDs of all selected merchants
        deductionRate,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Deduction</Typography>
      <Autocomplete
        multiple
        disablePortal
        options={merchants}
        getOptionLabel={(m) => `[${m.merchantId}] - ${m.merchantName}`}
        getOptionKey={(m) => m._id}
        onChange={(e, selected) => onChangeMerchants(selected as MerchantData[])}
        value={selectedMerchants}
        renderInput={(params) => <TextField {...params} label="Merchants" />}
        hidden={form == 'bulkEdit'}
      />
      <TextField
        type="number"
        onWheel={(e: any) => e.target.blur()}
        onChange={onChange}
        name="deductionRate"
        label="Deduction Rate"
        value={data.deductionRate}
        inputProps={{
          max: 100,
          min: 0,
        }}
        InputProps={{
          endAdornment: <>%</>,
        }}
      />
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={handleSubmit}
        disabled={loading || selectedMerchants.length === 0}
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
};

export default DeductionForm;
