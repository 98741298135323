import React, { useState, useRef } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import {
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '../../../components/LoadingButton';
import GroupFieldSelector from '../GroupFieldSelector';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (updatedOffer: any) => any;
  offerData: any;
};

const EditCustomOfferGroup = ({
  open,
  handleClose,
  onSubmit,
  offerData,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    label: offerData.label ?? '',
    key: offerData.key ?? '',
  });

  const fieldRef = useRef<any>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (!fieldRef.current) return;
    setLoading(true);

    const fields = fieldRef.current.getFields();

    await onSubmit({
      ...values,
      fields,
    });
    setLoading(false);
  };

  return (
    <SimpleModal
      open={open}
      handleClose={handleClose}
      containerStyle={{ minWidth: 600 }}
    >
      <Typography variant="h6" gutterBottom>
        Edit Custom Offer Group
      </Typography>
      <TextField
        label="Label"
        name="label"
        onChange={onChange}
        value={values.label}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Key"
        name="key"
        onChange={onChange}
        value={values.key}
        fullWidth
        margin="normal"
      />
      <GroupFieldSelector
        label="Fields"
        initialValues={offerData.fields}
        ref={fieldRef}
      ></GroupFieldSelector>
      <LoadingButton
        onClick={handleSubmit}
        variant="contained"
        loading={loading}
        sx={{ marginTop: 2 }}
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
};

export default EditCustomOfferGroup;
