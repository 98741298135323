import { Box, Typography, LinearProgress, CssBaseline } from '@mui/material';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const BIZRATE_LINK = 'https://rd.bizrate.com/rd';
const RID_KEY = 'af_rid';
const FBCLID = 'fbclid';

const FacebookRedirectPage = () => {
  const [queries, setQueries] = useSearchParams();

  const init = () => {
    const redirectUrl = new URL(BIZRATE_LINK);

    for (const [key, value] of Array.from(queries.entries())) {
      if (key === RID_KEY) continue;

      if (key === FBCLID) redirectUrl.searchParams.append(RID_KEY, value);
      redirectUrl.searchParams.append(key, value);
    }

    const redirectString = redirectUrl.toString();

    window.location.href = redirectString;
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <CssBaseline>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={3}
        width="100%"
        height="100vh"
      >
        <Typography variant="h6">Loading</Typography>
        <LinearProgress sx={{ width: 200 }} />
      </Box>
    </CssBaseline>
  );
};

export default FacebookRedirectPage;
