import React, { useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import SimpleModal from '../../../../components/SimpleModal';
import { Typography, Button, Box } from '@mui/material';

interface DateFilterProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: (range: Range) => void;
  initialData: Range;
}

function DateFilter({
  open,
  handleClose,
  onSubmit,
  initialData,
}: DateFilterProps) {
  const [dateRange, setDateRange] = useState<Range[]>([initialData]);

  const handleChange = (newValue: any) => {
    setDateRange(newValue);
  };

  const saveDateFilter = () => {
    onSubmit(dateRange[0]);
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Filter table by date</Typography>
      <Typography variant="body2">
        Export feature is also affected by this date range{' '}
      </Typography>

      <DateRange
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        onChange={(item) => handleChange([item.selection])}
      />
      <Box display="flex" alignItems="center" gap={2}>
        <Button variant="contained" onClick={saveDateFilter} sx={{ flex: 1 }}>
          Save
        </Button>
        {/* <Button variant="outlined" onClick={clearDateFilter} sx={{ flex: 1 }}>
          Clear selection
        </Button> */}
      </Box>
    </SimpleModal>
  );
}

export default DateFilter;
