import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Chip } from '@mui/material';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';
import RequestTableToolbar from './Toolbar';
import { Range } from 'react-date-range';
import useClicks from '../useClicks';

function RequestTable() {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);

  const client = useClicks();

  const handleDateFilterSubmit = (newDateRange: Range) => {
    setDateRange(newDateRange);
    setLoading(true);
    setPaginationModel({ ...paginationModel, page: 0 });
    fetchData(
      0,
      paginationModel.pageSize,
      newDateRange.startDate,
      newDateRange.endDate
    );
  };

  const fetchData = async (
    page: number,
    pageSize: number,
    startDate: Date | undefined,
    endDate: Date | undefined
  ) => {
    const api = client.getClicks();

    try {
      const startDateFinal = startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD');
      const endDateFinal = endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD');

      const res = await api.call(startDateFinal, endDateFinal, page, pageSize);

      setData(res.clicks.result);
      setTotalRowCount(res.clicks.totalCount);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching data', error);
      setLoading(false);
    }
  };

  const handlePaginationChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
    fetchData(
      model.page,
      model.pageSize,
      dateRange.startDate,
      dateRange.endDate
    );
  };

  const capitalizeWords = (str: string) => {
    return str
      .split(' ')
      .map((word) => word.toUpperCase())
      .join(' ');
  };

  useEffect(() => {
    fetchData(
      0,
      paginationModel.pageSize,
      dateRange.startDate,
      dateRange.endDate
    );
  }, []);

  const formatBlockingReason = (blockingRules: any[]) => {
    if (!blockingRules || blockingRules.length === 0) return ['N/A'];

    const reasons: string[] = [];

    blockingRules.forEach((ruleObj: any) => {
      if (ruleObj.rule?.blockLocations) {
        const blockLocation = ruleObj.rule.blockLocations;
        blockLocation.locations.forEach((loc: any) => {
          const type = loc.type ?? 'Unknown';
          const country = loc.country?.name ?? 'Unknown Country';
          const state =
            typeof loc.state === 'string' && loc.state === 'all'
              ? 'All States'
              : loc.state?.name ?? 'Unknown State';
          const city =
            typeof loc.city === 'string' && loc.city === 'all'
              ? 'All Cities'
              : loc.city?.name ?? 'Unknown City';

          reasons.push(`${type.toUpperCase()} - ${country}, ${state}, ${city}`);
        });
      } else {
        const ruleKeys = ruleObj.rule ? Object.keys(ruleObj.rule) : [];
        ruleKeys.forEach((key) => {
          reasons.push(capitalizeWords(key.replace(/([A-Z])/g, ' $1').trim()));
        });
      }
    });

    return reasons.length > 0 ? reasons : ['N/A'];
  };

  const tableCols = [
    {
      headerName: 'ID',
      field: '_id',
      flex: 1,
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      flex: 1,
      valueGetter: (params: any) => {
        return new Date(params.row.createdAt)
          .toISOString()
          .slice(0, 16)
          .replace('T', ' ');
      },
    },
    {
      headerName: 'IP',
      field: 'ip',
      flex: 1,
      valueGetter: (params: any) => params.row.request?.ip ?? 'N/A',
    },
    {
      headerName: 'User Agent',
      field: 'userAgent',
      flex: 2,
      valueGetter: (params: any) => {
        return params.row?.request?.userAgent || 'N/A';
      },
    },
    {
      headerName: 'Location',
      field: 'location',
      flex: 2,
      valueGetter: (params: any) => {
        if (!params.row.headers) return 'N/A';

        const city = params.row.headers.geoip_city_name ?? '';
        const state = params.row.headers.geoip_state_code ?? '';
        const country = params.row.headers.geoip_country_code ?? '';

        const location = [city, state, country].filter(Boolean).join(', ');

        return location || 'N/A';
      },
    },
    {
      headerName: 'Is Blocked',
      field: 'isBlocked',
      flex: 1,
      renderCell: (params: any) => {
        const isBlocked = params.value;

        return (
          <Chip
            label={isBlocked ? 'Blocked' : 'Not Blocked'}
            color={isBlocked ? 'error' : 'success'}
          />
        );
      },
    },
    {
      headerName: 'Block Reason',
      field: 'block_reason',
      flex: 2,
      renderCell: (params: any) => {
        const reasons = formatBlockingReason(params.row.blockingRules);

        return (
          <Box display="flex" gap={1} flexWrap="wrap" sx={{ padding: '8px' }}>
            {reasons.map((reason: string, index: number) => (
              <Chip
                key={index}
                variant="outlined"
                color={reason === 'N/A' ? 'default' : 'error'}
                label={reason}
              />
            ))}
          </Box>
        );
      },
    },
  ];

  return (
    <Box mt={4}>
      <Box mb={2}>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Chip
            variant="outlined"
            color="primary"
            label={`Starts From: ${moment(dateRange.startDate).format(
              'YYYY-MM-DD'
            )}`}
          ></Chip>
          <Chip
            variant="outlined"
            color="primary"
            label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
          ></Chip>
        </Box>
      </Box>
      <DataGrid
        autoHeight
        rows={data}
        columns={tableCols}
        getRowId={(row) => row._id}
        loading={loading}
        paginationMode="server"
        rowCount={totalRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[5, 10, 25, 100]}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        getRowHeight={() => 'auto'}
        slots={{
          toolbar: () => (
            <RequestTableToolbar
              onDateFilterSubmit={handleDateFilterSubmit}
              initialDateRange={dateRange}
            />
          ),
        }}
      />
    </Box>
  );
}

export default RequestTable;
